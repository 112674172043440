import React, { Component } from "react";
import "../styles/main.scss";

import Footer from "../components/footer";

export default class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }

        this.key = 0;
    }

    componentDidMount() {
        fetch("/data/contact.json").then((r) => {
            r.json().then((r) => this.setState({ data: r.data }));
        });
    }

    renderItem = (item) => {
        this.key++;

        if(item.name !== "map") {
            return(
                <div key={ this.key } className="item">
                    <div className="itemTitle"><div className={ "icon fa fa-" + item.icon } />{ item.name + ":" }</div>
                    <div>{ item.value }</div>
                </div>
            )
        } else {
            return(<div key={ this.key } className="item"><iframe title={ item.name } src={ item.url } /></div>)
        }
    }

    render() {
        return(
            <div>
                <div className="nest">
                    <div className="section">
                        <div className="image tall">
                            <div className="tint" />
                            
                            <img src="/assets/park.jpg" alt="Alaska Teknoloji Bina" />
                        </div>
                    </div>

                    <div className="section">
                        <div className="title">Firma Bilgileri</div>
                        <div className="line" />

                        <div className="single">
                            <div className="list">
                                { this.state.data ? this.state.data.firm.map((i) => this.renderItem(i)) : null }
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="title">Banka Bilgileri</div>
                        <div className="line" />

                        <div className="single">
                            <div className="list">
                                { this.state.data ? this.state.data.bank.map((i) => this.renderItem(i)) : null }
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="title">Adres</div>
                        <div className="line" />

                        <div className="single">
                            <div className="list two">
                                { this.state.data ? this.state.data.location.map((i) => this.renderItem(i)) : null }
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}